@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  font-family: 'Merriweather Sans', Helvetica, Arial, sans-serif;
  line-height: 1.2;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  body {
    background-size: cover;
  }

  #root {
    width: 100vw; /* Set the width to 100% of the viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
}

@media (max-width: 1368px) {
  body {
    background-size: cover;
  }

  #root {
    width: 100vw; /* Set the width to 100% of the viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
}

nav {
  font-family: 'Candara', 'Merriweather Sans', Helvetica, Arial, sans-serif;
}

a {
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

hr {
  border-style: double;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

hr + p {
  margin-top: 1rem;
}

ul {
  list-style-type: none;
  margin: 0 0 1.5rem 0;
  padding: 0;
}

ul li {
  padding-left: 1rem;
  text-indent: -1rem;
}

ul li:before {
  content: '•';
  padding-right: 0.5rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

body.earth {
  background-image: url(../images/bg_earth.jpg);
  color: #041859;
  background-color: #0565d6;

  a {
    outline: none;
    color: #0565d6;
    font-weight: 600;
  }
}

body.air {
  background-image: url(../images/bg_air.jpg);
  color: #24386b;
  background-color: #316f99;

  a {
    outline: none;
    color: #096dd1;
    font-weight: 600;
  }
}

body.ether {
  background-image: url(../images/bg_ether.jpg);
  color: #241328;
  background-color: #5f4064;

  a {
    outline: none;
    color: #51295b;
    font-weight: 600;
  }
}

body.fire {
  background-image: url(../images/bg_fire.jpg);
  color: #c04c00;
  background-color: #c04c00;

  a {
    outline: none;
    color: #c87700;
    font-weight: 600;
  }
}

body.water {
  background-image: url(../images/bg_water.jpg);
  color: #4c5b73;
  background-color: #657898;

  a {
    outline: none;
    color: #4c5b73;
    font-weight: 600;
  }
}

blockquote {
  margin: 0 0 1.5rem;
  padding: 1rem;
  font-size: 1.2rem;
  line-height: 1.4;
  background-color: rgba(white, 0.5);
  border-left: 1rem solid;
  border-left-color: rgba(white, 0.8);
  border-radius: 0.5rem;
  font-weight: 100;
}

blockquote p {
  margin: 0;
}

blockquote footer {
  margin-top: 1rem;
  font-style: italic;
  text-align: right;
}

blockquote footer:before {
  content: '\2014 \00A0';
}

.MuiDrawer-paper {
  max-width: 80%;
  padding: 1rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
