.float-left {
  float: left;
  margin-right: 2rem;
}

.float-right {
  float: right;
  margin-left: 2rem;
}

img.float-right,
img.float-left {
  margin-bottom: 1rem;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.hidden {
  display: none;
}
