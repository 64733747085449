.page {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Adjust this based on the height of your content */

  a {
    outline: none;
    font-weight: 600;
  }
}

.content {
  margin: 0 auto;
  width: 960px;
  height: auto;
}

@media (max-width: 767px) {
  .content {
    margin: 0.5rem;
    width: auto;
    height: auto;
    min-height: 100vh;
  }
}

@media (max-width: 1368px) {
  .content {
    margin: 0 1rem;
    width: auto;
    height: auto;
    min-height: 100vh;
  }
}
