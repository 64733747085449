.header {
  width: 960px;
  height: 135px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;

  &:global(.water) {
    background-image: url(../../assets/images/lvdr_logo_water.png);
  }

  &:global(.fire) {
    background-image: url(../../assets/images/lvdr_logo_fire.png);
  }

  &:global(.ether) {
    background-image: url(../../assets/images/lvdr_logo_ether.png);
  }

  &:global(.earth) {
    background-image: url(../../assets/images/lvdr_logo_earth.png);
  }

  &:global(.air) {
    background-image: url(../../assets/images/lvdr_logo_air.png);
  }
}

.tagLine {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  font-size: 2rem;
  font-style: italic;
}

@media (max-width: 767px) {
  .header {
    display: none;
  }

  .mobile {
    display: inline;
  }
}
