.navigation {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  margin: 1px 0;

  ul {
    margin: 0;
    padding: 0 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
  }

  li:before {
    content: '';
  }

  li {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: auto;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
    vertical-align: middle;
  }

  :global(.air) & {
    background-color: #ffffff80;
  }

  :global(.earth) & {
    background-color: #ffffff80;
  }

  :global(.ether) & {
    background-color: #ffffff80;
  }

  :global(.fire) & {
    background-color: #ffffff80;
  }

  :global(.water) & {
    background-color: #ffffff80;
  }

  a {
    text-decoration: none;
  }
}

.drawer {
  width: 3rem !important;
  height: 3rem !important;
}

@media (max-width: 767px) {
  .navigation {
    display: flex;
    flex-direction: row;
    height: 7rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 70%;

    &:global(.water) {
      background-image: url(../../assets/images/lvdr_logo_water.png);
    }

    &:global(.fire) {
      background-image: url(../../assets/images/lvdr_logo_fire.png);
    }

    &:global(.ether) {
      background-image: url(../../assets/images/lvdr_logo_ether.png);
    }

    &:global(.earth) {
      background-image: url(../../assets/images/lvdr_logo_earth.png);
    }

    &:global(.air) {
      background-image: url(../../assets/images/lvdr_logo_air.png);
    }

    ul {
      display: none;
    }
  }
}
