.preloadImages {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
}

.preloadImage {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.preloadImage.earth {
  background-image: url(../../assets/images/bg_earth.jpg);
}

.preloadImage.air {
  background-image: url(../../assets/images/bg_air.jpg);
}

.preloadImage.ether {
  background-image: url(../../assets/images/bg_ether.jpg);
}

.preloadImage.fire {
  background-image: url(../../assets/images/bg_fire.jpg);
}

.preloadImage.water {
  background-image: url(../../assets/images/bg_water.jpg);
}

.preloadImage.earthLogo {
  background-image: url(../../assets/images/lvdr_logo_earth.png);
}

.preloadImage.airLogo {
  background-image: url(../../assets/images/lvdr_logo_air.png);
}

.preloadImage.etherLogo {
  background-image: url(../../assets/images/lvdr_logo_ether.png);
}

.preloadImage.fireLogo {
  background-image: url(../../assets/images/lvdr_logo_fire.png);
}

.preloadImage.waterLogo {
  background-image: url(../../assets/images/lvdr_logo_water.png);
}
