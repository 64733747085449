.content {
  display: flex;
  flex-direction: row;
}

.toc {
  padding: 2.5rem;
  margin-right: 1px;
  width: 30%;

  li > ul {
    font-size: 0.9rem;
    filter: opacity(0.9);
    margin-top: 0.5rem;
  }

  li + li {
    margin-top: 0.5rem;
  }

  :global(.air) & {
    background-color: #ffffff80;
  }

  :global(.earth) & {
    background-color: #ffffff80;
  }

  :global(.ether) & {
    background-color: #ffffff80;
  }

  :global(.fire) & {
    background-color: #ffffff80;
  }

  :global(.water) & {
    background-color: #ffffff80;
  }
}

.contentBody {
  padding: 0 2.5rem 1.5rem 2.5rem;
  flex: 1;
  height: 100%;

  :global(.air) & {
    background-color: #ffffff80;
  }

  :global(.earth) & {
    background-color: #ffffff80;
  }

  :global(.ether) & {
    background-color: #ffffff80;
  }

  :global(.fire) & {
    background-color: #ffffff80;
  }

  :global(.water) & {
    background-color: #ffffff80;
  }
}

.link {
  cursor: pointer;
}

@media (max-width: 767px) {
  .toc {
    display: none;
  }

  .breadcrumb {
    display: none;
  }

  .contentBody {
    padding-top: 2.5rem;
  }
}

@media (max-width: 1368px) {
  .toc {
    display: none;
  }
}
