.breadcrumbs {
  margin: 1rem 0 2rem 0;
  a {
    text-decoration: none;
  }
  ol {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  li + li {
    margin-left: 1rem;
    ::before {
      content: '>';
      padding-right: 1rem;
    }
  }
}

@media (max-width: 767px) {
  .breadcrumbs {
    display: none;
  }
}
