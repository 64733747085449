.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: top center;
  height: auto;
  background-attachment: fixed;

  &:global(.air) {
    background-image: url(../../assets/images/bg_air.jpg);
    color: #24386b;
    background-color: #316f99;

    a {
      outline: none;
      color: #096dd1;
      text-decoration: none;
      font-weight: 600;
    }
  }
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.lotus {
  width: 400px;
  height: auto;
  animation: spin 5s linear infinite;
  position: relative;
  z-index: 1;
}

.label {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
