.footer {
  margin-top: 1px;
  margin-bottom: 5rem;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;

  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  :global(.air) & {
    background-color: #ffffff80;
  }

  :global(.earth) & {
    background-color: #ffffff80;
  }

  :global(.ether) & {
    background-color: #ffffff80;
  }

  :global(.fire) & {
    background-color: #ffffff80;
  }

  :global(.water) & {
    background-color: #ffffff80;
  }
}

@media (max-width: 767px) {
  .footer {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 1368px) {
  .footer {
    margin-bottom: 1rem;
  }
}
